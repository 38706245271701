import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import customTheme from "@themes/project.json";
import createTheme from "@material-ui/core/styles/createTheme";
import ThemeProvider from "@material-ui/styles/ThemeProvider";

import "@css/index.css";

const Core = lazy(() => import(/* webpackChunkName: "reactCore" */ "@core"));

const theme = createTheme(customTheme);

const App = () => {
	return (
		<ThemeProvider theme={theme}>
			<Suspense fallback={<></>}>
				<Core />
			</Suspense>
		</ThemeProvider>
	);
};

ReactDOM.render(<App />, document.getElementById("root"));
